//公共产品管理列表
export const cols = [
  {
    prop: 'createByName',
    label: '分销商'
  },
  {
    label: '刀版图',
    minWidth: '150',
    slotName: 'knifeImageSlot'
  },
  {
    label: '效果图',
    minWidth: '150',
    slotName: 'showImageSlot'
  },
  {
    label: '原图',
    minWidth: '150',
    slotName: 'originalImageSlot'
  },
  {
    label: '是否侵权',
    slotName: 'tortSlot',
    minWidth: '150'
  },
  {
    label: '状态',
    slotName: 'stateSlot',
    minWidth: '80'
  },
  {
    label: '作图时间',
    slotName: 'timeSlot',
    minWidth: '150'
  },
  {
    label: '上架时间',
    slotName: 'putTimeSlot',
    minWidth: '150'
  }
]
